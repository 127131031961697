<template>
  <div class="wl-home">
    <!-- 视频广场 -->
    <div class="w">
      <box-2></box-2>
    </div>
  </div>
</template>

<script>
import Store from "../../store";
import Box2 from "../home/box2.vue";
export default {
  components: {
    Box2,
  },
  mounted() {},

  data() {
    return {
      uti: Store.uti,
    };
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.wl-home {
  // height: 600px;
  min-height: 600px;
  // margin-top: 200px;
  padding-bottom: 50px;
}
</style>
